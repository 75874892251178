import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuthUser } from 'react-auth-kit';
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import Swal from "sweetalert2";
import { formatAmount } from "../../utils/helper";
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { showToast } from "../../slices/toast-slice";
import { Modal } from "bootstrap";
import GlobalConfirmPasscod from "../GlobalConfirmPasscod";
import { isEmptyObject } from "jquery";

const WalletRepayment = () => {

    const navigate = useNavigate();
    const { t } = useTranslation();
    const authUser = useAuthUser();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const [agentLocation, setAgentLocation] = useState([]);
    const [fees, setFees] = useState([]);
    const [showWalletNumber, setShowWalletNumber] = useState(false);
    const [min, setMin] = useState(0);
    const [max, setMax] = useState(0);
    const [amount, setAmount] = useState('');
    const [fee, setFee] = useState('');
    const [total, setTotal] = useState(0); // Assuming you have a state for total
    const [txnLimitReached,setTxnLimitReached] = useState('');
    const dispatch = useDispatch();
    const [currency, setCurrency] = useState('');
    const [comment, setComment] = useState('');
    const [walletType, setWalletType] = useState('');
    const [walletProviders, setWalletProviders] = useState([]);
    const [wallet, setWallet] = useState('');
    const [walletNumber, setWalletNumber] = useState('');
    const [walletFound, setWalletFound] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [userDetails, setUserDetails] = useState(null);
    const LANGUAGE = useSelector((state) => state.auth.LANGUAGE);

    useEffect(() => {
        dispatch(showLoading());
        axios({method: 'get', url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/userProfileWithData', 
            withCredentials: false,
            headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            dispatch(hideLoading());
            setUserDetails(res.data.data)

        }).catch(er => {
            dispatch(hideLoading());
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        });
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        const data = {
            method: "Wallet"
        }
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getFeesSendToAndPickupAt',data,{headers})
        .then(response => {
            if(response.data.status === 1){ 
                setAgentLocation(response.data.data.agentLocation);
                setFees(response.data.data.fees);
                setWalletType(response.data.data.walletType);
                setWalletProviders(response.data.data.walletProviders);
            }else{
                if(response.data.status === 0 && response.data.message === "PASSCOD_VALIDATION"){
                    handleAlert();
                }
            }
        }).catch(err => {
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        })
    }, []);


    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    function initiateRepayment (event) {
        
        if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
            openModal();
            return;
        }
        //call the doRepayment function
        doRepayment();
    }

    //Do the repayment
    const doRepayment = () => {
        setBtnDisabled(true);
        setIsModalOpen(false);
        dispatch(showLoading());
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        const data = {
            method: walletType === 2 ? "Manual Wallet" : "Wallet",
            currency: (currency !== null && currency !== "") ? currency : globalAuthUser?.location?.currency,
            amount:amount,
            agent_comment:comment,
            fees:fee,
            origin:"POS",
            walletType: wallet,
            agentLocationId: globalAuthUser?.location?.id
        }
        //call the checkLimit api 
        axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/txnLimitsCheck',data,{headers})
        .then(response => {
            if(response.data.status === 1){ 
                dispatch(hideLoading());
                if(!isEmptyObject(response.data.data)){
                    if (response.data.data.max_limit_per_txn !== "") {
                        dispatch(showToast(["danger", t("INFORMATION"), t('LIMIT_PER_TRANSACTION_REACHED',{ amount: formatAmount(response.data.data.max_limit_per_txn, LANGUAGE)})])); 
                    }
                    if (response.data.data.max_daily_limit_per_location !== "") {
                        dispatch( showToast(["danger", t("INFORMATION"), t('DAILY_LIMIT_PER_LOCATION_REACHED',{ amount: formatAmount(response.data.data.max_daily_limit_per_location, LANGUAGE)})])); 
                    }
                    return;
                }else{
                    dispatch(showLoading());
                    axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/initiateRepayment',data,{headers})
                    .then(response => {
                        setBtnDisabled(true);
                        console.log(response.data);
                        if(response.data.status === 1){ 
                            let data = response.data.data;
                            if(response.data.message === "REPAYMENT_REQUEST_SUBMITED_SUCCESSFULLY"){
                                const btn = document.getElementsByClassName('btn')[0];
                                // Navigate to a new page after a delay
                                btn.setAttribute('disabled','disabled');
                                dispatch( showToast(["success", t("INFORMATION"), t('REPAYMENT_REQUEST_SUBMITED_SUCCESSFULLY', {data})])); 
                                setTimeout(() => {
                                    navigate('/repayment-requests');
                                }, 1000);
                            }
                        }else{
                            dispatch(hideLoading());
                            if (response.data.message === "AMOUNT_LIMIT_EXCEED") {
                                dispatch( showToast(["danger", t("INFORMATION"), t('AMOUNT_LIMIT_EXCEED')])); 
                            }
                            if (response.data.message === "REPAYMENT_AMOUNT_EXCEEDED") {
                                dispatch( showToast(["danger", t("INFORMATION"), t('REPAYMENT_AMOUNT_EXCEEDED')])); 
                            }
                            if (response.data.message === "MAX_CREDIT_AMOUNT_REACHED") {
                                dispatch( showToast(["danger", t("INFORMATION"), t('MAX_CREDIT_AMOUNT_REACHED')])); 
                            }
                            if (response.data.message === "REPAYMENT_PENDING_AMOUNTS") {
                                dispatch( showToast(["danger", t("INFORMATION"), t('REPAYMENT_PENDING_AMOUNTS')])); 
                            }
                            if(response.data.message === "IDENTICAL_TXN"){
                                dispatch( showToast(["danger", t("INFORMATION"), t('IDENTICAL_TXN')])); 
                            }
                            if (response.data.message === "VALID_PICKUP_CODE_NOT_FOUND") {
                                dispatch( showToast(["danger", t("INFORMATION"), t('VALID_PICKUP_CODE_NOT_FOUND')])); 
                            }
                            setBtnDisabled(false);
                        }
                    }).catch(err => {
                        dispatch(hideLoading());
                        dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
                        setBtnDisabled(false);
                    })
                }
            }else{
                dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
                return;
            }
            setBtnDisabled(false);
        }).catch(err => {
            dispatch(hideLoading());
            dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            setBtnDisabled(false);
            return;
        });
        
    }

    const handleAlert = () => {
        Swal.fire({
            title: t('PASSCODE_INVALID'),
            confirmButtonText: t('UPDATE'),
            cancelButtonText: t('CANCEL'),
            showCancelButton: true,
            html: t('PASSCOD_VALIDATION'), 
            }).then((result) => {
                if (result.value !== undefined && result.value === true) {
                    console.log('passcode');
                    navigate('/create-passcode');
                }else{
                    console.log('repayment');
                    navigate('/repayment-requests');
                }
            }
        );
    }

    const handleWalletChange = (event) => {
        setBtnDisabled(true);
        const newWalletType = event.target.value;
        setWallet(newWalletType);
        if (newWalletType) {
            //call the checkproviders api
            const headers = {
                'Authorization': authUser().tokenType + ' ' + authUser().token
            };
            const data = {
                walletType: newWalletType
            }
            axios.post(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getLocationProviders',data,{headers})
            .then(response => {
                if(response.data.status === 1){ 
                    setBtnDisabled(false);
                    setWalletNumber(response.data.data.walletNumber);
                    setWalletFound('');
                }else{
                    setWalletNumber('');
                    setWalletFound(t(response.data.message)); //PROVIDER_NOT_FOUND
                }
            }).catch(err => {
                dispatch( showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
            })
        } else {
            setShowWalletNumber(false);
            setWalletNumber('');
        }
        handleAmountChange({ target: { value: amount } });
    };

    const handleAmountChange = (event) => {
        const newAmount = parseFloat(event.target.value); // Convert to float
        setAmount(newAmount);
        let minimum = 0;
        let maximum = 0;
        if (fees !== undefined && fees.length !== 0){
            const minimumAmounts = fees.map(item => item.minimum_amount);
            const maximumAmounts = fees.map(item => item.maximum_amount);
            minimum = Math.min(...minimumAmounts)
            setMin(minimum);
            maximum = Math.max(...maximumAmounts);
            setMax(maximum);
        }

        let newFee = 0;
        let newTotal = 0;
        let bnbOwned = globalAuthUser?.location?.bnb_owned;
        let maxCredit = globalAuthUser?.location?.max_credit_limit;
        let balance = globalAuthUser?.location?.balance;
    
        if (!isNaN(newAmount) && newAmount !== 0) {
            // Consolidate conditions for readability
            if (bnbOwned && newAmount > parseFloat(balance) + parseFloat(maxCredit)) {
                // Show txn-limit-reached message
                setFee('');
                setTotal(0);
                setTxnLimitReached('');
                dispatch(showToast(["danger", t("INFORMATION"), t('INSUFICIENT_BALANCE')])); 
            } else if (!bnbOwned && newAmount > parseFloat(balance)) {
                // Show txn-limit-reached message
                setFee('');
                setTotal(0);
                setTxnLimitReached('');
                dispatch(showToast(["danger", t("INFORMATION"), t('REPAYMENT_AMOUNT_EXCEEDED')]));
            } else {
                let feeFound = false;
    
                for (const f of fees) {
                    if (f.minimum_amount <= newAmount && f.maximum_amount >= newAmount) {
                        if (f.charges_type === 1) {
                            newFee = newAmount * (f.charges / 100);
                        } else {
                            newFee = f.charges;
                        }
                        feeFound = true;
                        break;
                    }
                }
                console.log(newFee);
                if (!feeFound) {
                    newFee = 0; // Set fee to 0 if no fee range matches
                }
    
                if (newAmount < minimum) {
                    resetAlert('min',minimum);
                } else if (newAmount > maximum) {
                    resetAlert('max',maximum);
                } else {
                    setTxnLimitReached('');
                }
            }
        } else {
            if (amount < min) {
                console.log('min min min');
                resetAlert('min',min);
            } else if (amount > max){
                console.log('max max max');
                resetAlert('max',max);
            }
        }
    
        newTotal = newAmount + newFee; // Calculate new total
        setFee(newFee);
        setTotal(isNaN(newAmount) || isNaN(newFee) ? newTotal : newTotal);
    };
    

    function resetAlert(type, val) {
        // Show txn-limit-reached message
        const alertMsg = getAlertMsg(type, val);
        // Update the state to display the alert message
        setTxnLimitReached(alertMsg);
    }
    
    function getAlertMsg(type, val) {
        if (type === 'min') {
            return `${t('LBL_MIN_AMOUNT')} ${formatAmount(val, LANGUAGE)} ${globalAuthUser?.location?.currency}`;
        } else if (type === 'max') {
            return `${t('LBL_MAX_AMOUNT')} ${formatAmount(val, LANGUAGE)} ${globalAuthUser?.location?.currency}`;
        }
    }

    const schema = yup.object().shape({
        walletType: yup.string().required(t('FIELD_REQUIRED')),
        //amount: yup.number("Invalid amount").positive().required(t('FIELD_REQUIRED')),
    })

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange"
    });
    

    return (
        <React.Fragment>
            <div style={{ padding: "20px" }}>
                <div className="card">
                    <div className="card-header row">
                        <h5 className="col-sm-4">{t('WALLET_REPAYMENT')}</h5>
                        <div className="col-sm-4">
                            <p> <span style={{ fontSize: '23px', marginRight: '10px'}}>Balance: </span> <span style={{ fontSize: '23px', fontWeight: 'bold'}}>
                                { formatAmount(userDetails?.location?.balance, LANGUAGE) + ' ' + userDetails?.location?.currency }
                                </span> 
                            </p>
                        </div>
                        <div className="col-sm-4">
                            <div className="card-toolbar float-end mb-2 mt-2">
                                <button type="button" className="btn btn-primary" onClick={() => navigate('/repayment-requests')}>{t("BACK")}</button> &nbsp; &nbsp;
                            </div>
                        </div>
                    </div>
                    
                    <div className="card-body">
                        <form action="" onSubmit={handleSubmit(initiateRepayment)}>
                            <div className="row">
                                <div className="col-sm-6">
                                    <div className="form-group col-md-12 mb-3">
                                        <label className="" htmlFor="amount">{ t('AMOUNT') } <span className="text-danger">*</span></label>
                                        <input type="number" name="amount" placeholder="Amount" id="amount" className={`form-control ${errors.amount ? 'is-invalid' : ''}`} {...register('amount', {
                                            onChange: (e) => handleAmountChange(e),
                                        })} required/>
                                        {errors?.amount != null && <p className='input-error-msg' style={{ marginBottom: "0px" }}> {errors?.amount?.message} </p>}
                                        {
                                            txnLimitReached && <span className="text-danger">{txnLimitReached}</span>
                                        }
                                    </div>
                                    <div className="form-group col-md-12 mb-3">
                                        <label className="" htmlFor="currency">{ t('CURRENCY') } <span className="text-danger">*</span></label>
                                        <select
                                            key={globalAuthUser?.location?.currency} // Ajoutez une clé unique ici
                                            name="currency"
                                            id="currency"
                                            aria-label="Default select example"
                                            className="form-select mb-3"
                                            onChange={(e) => {
                                                setCurrency(e.target.value);
                                            }}
                                            required
                                            value={currency} // 
                                        >
                                            <option value={globalAuthUser?.location?.currency} hidden>
                                                {globalAuthUser?.location?.currency}
                                            </option>

                                        </select>

                                    </div>
                                    <div className="form-group col-md-12 mb-3">
                                        <label className="" htmlFor="walletType">{ t('WALLET_TYPE') } <span className="text-danger">*</span></label>
                                        <select name="walletType" 
                                        id="walletType" aria-label="Default select example" required value={wallet}
                                        className={`form-select ${errors.walletType ? 'is-invalid' : ''}`} {...register('walletType', {
                                            onChange: (e) => handleWalletChange(e),
                                        })}
                                        >
                                            <option value={""} selected>{t('SELECT_WALLET_TYPE')}</option>
                                            {
                                                Object.keys(walletProviders).map((key, index) => (
                                                    <option key={index} value={key}>
                                                    {walletProviders[key]}
                                                    </option>
                                                ))
                                            }
                                        </select>
                                        {errors?.walletType != null && <p className='input-error-msg' style={{ marginBottom: "0px" }}> {errors?.walletType?.message} </p>}
                                        {
                                            walletFound && <span className="text-danger">{walletFound}</span>
                                        }
                                    </div>
                                    <div className="form-group col-md-12 mb-3">
                                        <label className="" htmlFor="comment">{ t('COMMENT') } </label>
                                        <input type="text" name="comment" className="form-control" placeholder="Comment"
                                        onChange={(e) => {
                                            setComment(e.target.value);
                                        }}
                                        />
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="card mt-8 mt-md-0">
                                        <div className="card-header">
                                            <h6 className="mb-0 py-1">{ t('SUMMARY') }</h6>
                                        </div>
                                        <ul className="list-group list-group-flush text-md">
                                            <li className="list-group-item d-flex justify-content-between fs-14">
                                                <span className="fw-600">{ t("MOBILE_WALLET_NUMBER") }</span>
                                                <span className="">
                                                    {
                                                        walletNumber
                                                        ? (walletNumber) 
                                                        : ""
                                                    }
                                                </span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between fs-14">
                                                <span className="fw-600">{ t("AMOUNT") }</span>
                                                <span className="amountSpan">{formatAmount(amount, LANGUAGE)}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between fs-14">
                                                <span className="fw-600">{ t("FEES") }</span>
                                                <span className="feeSpan">{formatAmount(fee, LANGUAGE)}</span>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between fs-14">
                                                <span className="fw-600">{ t("YOUR_TOTAL") }</span>
                                                <span className="totalSpan">{formatAmount(total, LANGUAGE)}</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="form-group row justify-content-md-center mt-3">
                                    <button type="submit" disabled={(walletNumber && fee!==0 && !btnDisabled) ? "" : ('disabled') } className="col-sm-2 btn btn-primary" id="apply-filter-paid" data-count="0">{t('SUBMIT')}</button>
                                    &nbsp;
                                    <button type="reset" onClick={(e) => setFee(0)} className="col-sm-2 btn btn-secondary" id="apply-filter-clear-paid">{t('CANCEL')}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div> 
            </div>
            <div>
                <GlobalConfirmPasscod
                    isOpen={isModalOpen}
                    onClose={closeModal}
                    callParentFunction={doRepayment}
                />
            </div>
        </React.Fragment>
    );
}

export default WalletRepayment;