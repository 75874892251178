import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAuthUser } from 'react-auth-kit';
import ReactDom from 'react-dom';
import { createRoot } from 'react-dom/client'
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../slices/toast-slice";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import { formatAmount } from '../../utils/helper'

const PaidTxnShow = () => {

    const { agentTxnId } = useParams();
    let LANGUAGE = useSelector((state) => state.auth.LANGUAGE);
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const [loadCompleted, setLoadCompleted] = useState(false);
    const [showMgiReversal, setShowMgiReversal] = useState(false);
    const [txnDetails, setTxnDetails] = useState([]);
    const [showReversalModal, setShowReversalModal] = useState("");
    const [displayReversalModal, setDisplayReversalModal] = useState("none");
    const [reversalReason, setReversalReason] = useState("");
    const [refNoResp, setRefNoResp] = useState(null);
    const navigate = useNavigate();
    const { t } = useTranslation();
    const authUser = useAuthUser()
    const dispatch = useDispatch();


    var mReceverData = [
        "firstName",
        "lastName",
        "amount",
        "address",
        "phoneNumber",
        "photoIdType",
        "photoIdNumber",
        "issuingCountry",
        "issueDate",
        "expiryDate",
        "DATE_OF_BIRTH",
    ]

    var mTxnData = [
        "company",
        "paidDate",
        "paidBy",
        "senderCountry",
        "senderFirstName",
        "senderLastName",
        "senderPhone"
    ]

    var reverseReasons = {
        WANTS_CASH: t("RECEIVER_REVERSAL_REASONS.WANTS_CASH"),
        WRONG_TX: t("RECEIVER_REVERSAL_REASONS.WRONG_TX"),
        OUT_OF_CHECKS: t("RECEIVER_REVERSAL_REASONS.OUT_OF_CHECKS"),
        ID_PROBLEM: t("RECEIVER_REVERSAL_REASONS.ID_PROBLEM"),
    }

    useEffect(() => {
        console.log('user', globalAuthUser)
        axios({
            method: "post",
            url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/partner/receiveTransaction/' + agentTxnId,
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            },
        }).then((res) => {
            console.log(res);
            if (res.data.status === 1) {
                setTxnDetails(res.data.data);
                // checkForMgiReversalShow()
                setLoadCompleted(true);
            }

        });
    }, []);

    useEffect(() => {
        checkForMgiReversalRule()
    }, [txnDetails])

    const onBack = () => {
        navigate("/paid-transactions");
        console.log("company", txnDetails.company)
    };

    const onReverse = () => 
    {
        dispatch(showLoading());
        axios({
            method: "post",
            url: process.env.REACT_APP_PARTNER_MODULE_BASE_URL + "/api/moneygram/referenceNumber",
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: 'Bearer ' + globalAuthUser?.partner_module_token
            },
            data: {
                agentID: globalAuthUser?.location.agent_id,
                agentSequence: globalAuthUser?.location.sequence,
                token: globalAuthUser?.location.token,
                referenceNumber: txnDetails.referenceNumber,
                language: LANGUAGE,
            },
        })
        .then((res) => {
            console.log(res);
            dispatch(hideLoading());
            if (res.data.status === "SUCCESS") {
                var refNo = res.data.data
                setRefNoResp(res.data.data)
                if(refNo.transactionStatus === 'RECVD') {
                    setShowReversalModal("show");
                    setDisplayReversalModal('block')
                } 
                else {
                    dispatch(showToast(["danger", "", t("CANT_REVERSE_RECEIVE_RECVD")]));
                }
            } else {
                dispatch(showToast(["danger", "", res.data.message]));
            }
        })
        .catch((error) => {
            dispatch(hideLoading());
            dispatch(
                showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")])
            );
        });

    }
    
    const onSubmitReversal = () => {
        dispatch(showLoading());
        console.log("globalAuthUser", globalAuthUser)
        axios({
            method: "post",
            url: process.env.REACT_APP_BNB_BASE_URL + "/api/portal/moneygram/reverseReceive",
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: authUser().tokenType + " " + authUser().token,
            },
            data: {
                agentID: globalAuthUser?.location.agent_id,
                agentSequence: globalAuthUser?.location.sequence,
                token: globalAuthUser?.location.token,
                language: LANGUAGE,
                receiveAmount: refNoResp.receiveAmount,
                referenceNumber: refNoResp.referenceNumber,
                operatorName: globalAuthUser.username,
                communicationRetryIndicator: false,
                receiveReversalReason: reversalReason,
            },
        })
        .then((res) => {
            dispatch(hideLoading());
            console.log(res);
            if (res.data.status == 1) {
                dispatch(showToast(["success", "SUCCESS", t(res.data.message)]));
                setShowReversalModal("");
                setDisplayReversalModal('none')
                navigate("/home");
            } else {
                dispatch(showToast(["danger", "FAILED", res.data.message]));
            }
            navigate("/home");
        })
        .catch((error) => {
            dispatch(hideLoading());
            console.log(error);
            dispatch(
                showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")])
            );
        });
    }

    const onReverseClose = () => {
        setShowReversalModal("");
        setDisplayReversalModal('none')
    }

    function checkForMgiReversalRule() 
    {
        console.log("company", txnDetails.company)
        if(txnDetails.company === 'MoneyGram') {
            setShowMgiReversal(true);
            return true;
        } 
        else {
            return false
        }
    }

    const onReversalReasonChange = (event) => {
        console.log("reversalReason", event.target.value)
        setReversalReason(event.target.value)
    }

    const onPrintReceipt = () => {
        var payload = {
            agentID: globalAuthUser?.location.agent_id,
            agentSequence: globalAuthUser?.location.sequence,
            token: globalAuthUser?.location.token,
            agentTxnId: agentTxnId
        };

        let apiUrl = "/api/portal/moneygram/reprintReceiveReceipt";
        if (txnDetails.companyCode !== "MG") {
            apiUrl = "/api/portal/receiptDom"; 
        }

        axios({
            method: "post",
            url: process.env.REACT_APP_BNB_BASE_URL + apiUrl,
            withCredentials: false,
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            },
            data: payload,
            responseType: 'blob'
        })
        .then((res) => {
            console.log(res);
            //Create a Blob from the PDF Stream
            const file = new Blob(
                [res.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        })
        .catch((error) => {
            dispatch(hideLoading());
            dispatch(
                showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")])
            );
        });
    }

      return (
        <>
          {loadCompleted && (
            <>
            <div style={{ padding: "20px" }}>
              <div className="card">
                <div className="card-header" style={{ fontWeight: "600" }}>
                    { t("TRANSACTION_DETAILS") } - { txnDetails.referenceNumber }
                </div>
                <div className="row" style={{ padding: "20px" }}>
                  <div className="row">
                    <div className="card" style={{ boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.08)", border: "none", height: "100%", marginBottom: "30px", padding: "20px 10px", }}>
                      <h5>Recever Details</h5>
                      <div className="card-body">
                        <div style={{}}>
                          {
                            mReceverData.map((field, ind) => {
                              if (ind % 2 === 0) {
                                return (
                                  <div className={`row rowsep ${ind % 4 === 0 ? 'odds' : ''} `}>
                                    <div className="col-md-6 row">
                                      <div className="col-md-5">
                                        <label htmlFor="">{t(field)}</label>
                                      </div>
                                      <div className="col-md-7">
                                        <span className="value">{txnDetails['receiver'][field]}</span>
                                      </div>
                                    </div>
                                    {
                                      ind + 1 < mReceverData.length &&
                                      <div className="col-md-6 row">
                                        <div className="col-md-5">
                                          <label htmlFor="">{t(mReceverData[ind +1])}</label>
                                        </div>
                                        <div className="col-md-7">
                                          <span className="value">{txnDetails['receiver'][mReceverData[ind +1]]}</span>
                                        </div>
                                      </div>
                                    }  
                                  </div>
                                )
                              }
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ marginTop: "30px" }}>
                    <div
                      className="card"
                      style={{ boxShadow: "0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 5px 0 rgba(0, 0, 0, 0.08)", border: "none", height: "100%", marginBottom: "30px",
                        padding: "20px 10px",
                      }}
                    >
                      <h5>Sender Details</h5>
                      <div className="card-body">
                        <div style={{}}>
                          {
                            mTxnData.map((field, ind) => {
                                if (ind % 2 === 0) {
                                return (
                                    <div className={`row rowsep ${ind % 4 === 0 ? 'odds' : ''} `}>
                                    <div className="col-md-6 row">
                                        <div className="col-md-5">
                                        <label htmlFor="">{t(field)}</label>
                                        </div>
                                        <div className="col-md-5">
                                        <span className="value">{txnDetails[field]}</span>
                                        </div>
                                    </div>
                                    {
                                        ind + 1 < mTxnData.length &&
                                        <div className="col-md-6 row">
                                        <div className="col-md-5">
                                            <label htmlFor="">{t(mTxnData[ind +1])}</label>
                                        </div>
                                        <div className="col-md-5">
                                            <span className="value">{txnDetails[mTxnData[ind +1]]}</span>
                                        </div>
                                        </div>
                                    }  
                                    </div>
                                )
                                }
                            })
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row" style={{  padding: ".75rem .5rem" }} >
                  <div style={{ textAlign: "right" }}>
                    <button className="btn btn-secondary" onClick={onBack}> {t("BACK")}  </button> &nbsp;     
                            <>
                            <button className="btn btn-primary" onClick={onPrintReceipt}> {t("PRINT_RECEIPT")}  </button> &nbsp;
                            </>
                    {/* {
                        showMgiReversal &&  <button className="btn btn-warning" onClick={onReverse}> {t("REVERSE")}  </button>
                    } */}
                  </div>
                </div>
              </div>
    
              
            </div>
          

            {/* // { */}
                <div>
                    <div  className={`modal fade ${showReversalModal}`} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel"
                        aria-hidden="true" role="dialog" tabIndex={-1}  style={{ display: displayReversalModal }} >
                        <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{t("RECEIVE_REVERSAL")}</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={onReverseClose} />
                                </div>
                                <div className="modal-body" style={{ maxHeight: "80vh", overflowY: "auto" }} >
                                    <div className="row padd-5">
                                        <div className="col-md-5">
                                            <label htmlFor="referenceNo"> { t("REFERENCE_NUMBER") } </label>
                                        </div>
                                        <div className="col-md-7 ft-wt-6">
                                            <label> { txnDetails.referenceNumber } </label>
                                        </div>
                                    </div>
                                    <div className="row padd-5 bg-alt">
                                        <div className="col-md-5">
                                            <label htmlFor="receiveAmount"> { t("RECEIVE_AMOUNT") } </label>
                                        </div>
                                        <div className="col-md-7 ft-wt-6">
                                            <label> { formatAmount(txnDetails.receiver.amount, LANGUAGE) } </label>
                                        </div>
                                    </div>
                                    <div className="row padd-5">
                                        <div className="col-md-5">
                                            <label htmlFor="receiveAmount"> { t("REVERSAL_REASON") } </label>
                                        </div>
                                        <div className="col-md-7 ft-wt-6">
                                            <select id="receiverOccupation" name="receiverOccupation" className="form-select form-control" onChange={onReversalReasonChange} >
                                                <option value="" defaultValue></option>
                                                {   Object.keys(reverseReasons).map((reason, index) => (
                                                        <option key={index} value={reason}>
                                                            {reverseReasons[reason]}
                                                        </option>
                                                    )
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button className="btn btn-secondary" onClick={onReverseClose}> {t("CLOSE")}  </button> &nbsp;
                                    <button className={`btn btn-primary ${reversalReason === "" ? 'disabled' : ''}`} onClick={onSubmitReversal}> {t("SUBMIT")}  </button> &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            {/* // } */}
            </>

            )}

        </>
      );
}

export default PaidTxnShow;