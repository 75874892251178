import { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from 'react-router-dom';
import { useAuthUser } from 'react-auth-kit';
import { FaPrint, FaArrowLeft, FaTimes } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { hideLoading, showLoading } from "../../../slices/loading-slice";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { useSendMoney } from "../../../hooks/useSendMoney";
import CustomModal from '../../common/customModal';
import { RequestCancelOrRequestCancelDetails } from './requestCancelOrRequestCancel';
import CustomLoading from '../../CustomLoading';
import { formatAmount } from '../../../utils/helper';

function SendMoneyDetails() {
    
    const [transaction, setTransaction] = useState({})
    const { reference } = useParams();
    const authUser = useAuthUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        handleCancelClick,
        cancelOrRequestCancel,
        t,
        toggleModal,
        isCancelable,
        loading,
        showModal,
        formikRef,
        LANGUAGE
      } = useSendMoney();
    
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);

    const getSenderName = () => {
        var name = transaction?.sender?.first_name
        if (transaction?.sender?.middle_name != null && transaction?.sender?.middle_name !== '') {
            name += ' ' + transaction?.sender?.middle_name
        }
        if (transaction?.sender?.last_name != null && transaction?.sender?.last_name !== '') {
            name += ' ' + transaction?.sender?.last_name
        }
        console.log('name', name)
        return name;
    }

    const getReceiverName = () => {
        var name = transaction?.receiver?.first_name
        if (transaction?.receiver?.middle_name != null && transaction?.receiver?.middle_name !== '') {
            name += ' ' + transaction?.receiver?.middle_name
        }
        if (transaction?.receiver?.last_name != null && transaction?.receiver?.last_name !== '') {
            name += ' ' + transaction?.receiver?.last_name
        }
        console.log('name', name)
        return name;
    }

    const receipt = () => {
        dispatch(showLoading())

        let url = ""
        let params = {}

        if(transaction.transaction.partner == 'MoneyGram') {
            url = process.env.REACT_APP_BNB_BASE_URL + '/api/portal/moneygram/receipt'
            params = {
                location: globalAuthUser?.location?.code,
                language: i18next.language,
                transaction_reference: transaction?.transaction?.pickup_code
            }
        } else if(transaction.transaction.partner == 'Ria') {
            url = process.env.REACT_APP_BNB_BASE_URL + "/api/portal/ria/generateReceipt"
            params = {
                reference_no: transaction.transaction.pickup_code,
                agent_location_code: transaction.transaction.sending_location,
            }
        } else if(transaction.transaction.partner == 'BNB') {
            url = process.env.REACT_APP_BNB_BASE_URL + "/api/portal/exportBnbSendMoneyReceipt/" + transaction.transaction.pickup_code
            params = { }
        }

        axios.post(
            url, params, {
            withCredentials: false,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
            },
            responseType: 'blob'
        }).then(res => {
            dispatch(hideLoading())

            console.log(res);
            var status = res?.data?.status
            if (status == 0) {
                // Display message
            }

            //Create a Blob from the PDF Stream
            const file = new Blob(
                [res.data],
                { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
        }).catch((error) => {
            console.log(error);
            dispatch(hideLoading());
        });
    }

    const updateTransactionStatus = () => {
        setTransaction(prev => ({
            ...prev,
            transaction: {
                ...prev.transaction,
                cancellation_requested: 1
            }
        }));
      };

    useEffect(() => {
        dispatch(showLoading());

        var params = {
            pickup_code: reference,
            is_sent: 1
        }

        axios.post(
            process.env.REACT_APP_BNB_BASE_URL + '/api/portal/partner/transaction', params, {
            withCredentials: false,
            headers: {
                'Authorization': authUser().tokenType + ' ' + authUser().token
            }
        }).then(res => {
            console.log(res);
            dispatch(hideLoading());
            console.log('tttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttttt', res.data.data);
            
            setTransaction(res.data.data)
        });

    }, [])


    return (
        <div>
            <div style={{ marginTop: "25px" }}>
                <div className='row'>
                    <div className='col-sm-12'>
                        <div className='card p-3'>
                            <div className="card-header d-flex justify-content-between">
                                <h5 className="card-label">Sent Transaction Details</h5>
                                <span>
                                    {
                                        (Object.keys(transaction).length !== 0 || transaction.constructor !== Object) &&
                                        <>
                                            <button type='button' className='btn btn-info' onClick={receipt}>
                                                <FaPrint size={18} /> &nbsp;
                                                {t('RECEIPT')}
                                            </button>  &nbsp;
                                        </>
                                    }
                                    <button type='button' className='btn btn-primary' onClick={() => navigate('/send-money')}>
                                        <FaArrowLeft size={18} fill={'white'} /> &nbsp;
                                        {t('BACK')}
                                    </button>
                                </span>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-9">
                                        <table className="table table-sm table-borderless w-100">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: '40%' }}>Sender Details:</th>
                                                    <th style={{ width: '60%' }}></th>
                                                </tr>
                                                <tr>
                                                    <td>Sender Name:</td>
                                                    <td>{getSenderName()}</td>
                                                </tr>
                                                <tr>
                                                    <td>Phone Number:</td>
                                                    <td>{transaction?.sender?.phone_number}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email:</td>
                                                    <td>{transaction?.sender?.email}</td>
                                                </tr>
                                                <tr>
                                                    <td>Country:</td>
                                                    <td>{transaction?.sender?.country_code}</td>
                                                </tr>
                                                <tr>
                                                    <td>Address:</td>
                                                    <td>{transaction?.sender?.address}</td>
                                                </tr>
                                                <tr>
                                                    <td>State:</td>
                                                    <td>{transaction?.sender?.state}</td>
                                                </tr>
                                                <tr>
                                                    <td>Zipcode:</td>
                                                    <td>{transaction?.sender?.zip}</td>
                                                </tr>
                                                <tr>
                                                    <td>City:</td>
                                                    <td>{transaction?.sender?.city}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table className="table table-sm table-borderless w-100">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: '40%' }}>Beneficiary Details:</th>
                                                    <th style={{ width: '60%' }}></th>
                                                </tr>
                                                <tr>
                                                    <td>Beneficiary Name:</td>
                                                    <td>{getReceiverName()}</td>
                                                </tr>
                                                <tr>
                                                    <td>Phone Number:</td>
                                                    <td>{transaction?.receiver?.phone_number}</td>
                                                </tr>
                                                <tr>
                                                    <td>Email:</td>
                                                    <td>{transaction?.receiver?.email}</td>
                                                </tr>
                                                <tr>
                                                    <td>Country:</td>
                                                    <td>{transaction?.receiver?.country_code}</td>
                                                </tr>
                                                <tr>
                                                    <td>Address:</td>
                                                    <td>{transaction?.receiver?.address}</td>
                                                </tr>
                                                <tr>
                                                    <td>State:</td>
                                                    <td>{transaction?.receiver?.state}</td>
                                                </tr>
                                                <tr>
                                                    <td>Zipcode:</td>
                                                    <td>{transaction?.receiver?.zip}</td>
                                                </tr>
                                                <tr>
                                                    <td>City:</td>
                                                    <td>{transaction?.receiver?.city}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table className="show-table table table-sm table-borderless w-100">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: '40%' }}>Receiving Option Details:</th>
                                                    <th style={{ width: '60%' }}></th>
                                                </tr>
                                                <tr>
                                                    <td>Receiving Option:</td>
                                                    <td>{transaction?.transaction?.receiving_option_code}</td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table className="show-table table table-sm table-borderless w-100">
                                            <tbody>
                                                <tr>
                                                    <th style={{ width: '40%' }}>Transaction Details:</th>
                                                    <th style={{ width: '60%' }}></th>
                                                </tr>
                                                <tr>
                                                    <td>Reference No:</td>
                                                    <td><strong>{transaction?.transaction?.pickup_code}</strong></td>
                                                </tr>
                                                <tr>
                                                    <td>Date:</td>
                                                    <td>{transaction?.transaction?.date}</td>
                                                </tr>
                                                <tr>
                                                    <td>Country:</td>
                                                    <td>{transaction?.transaction?.sending_country} - {transaction?.transaction?.receiving_country}</td>
                                                </tr>
                                                <tr>
                                                    <td>Currency:</td>
                                                    <td>{transaction?.transaction?.sending_currency} - {transaction?.transaction?.receiving_currency}</td>
                                                </tr>
                                                <tr>
                                                    <td>Amount:</td>
                                                    <td>{formatAmount(transaction?.transaction?.sending_amount, LANGUAGE)} ({transaction?.transaction?.sending_currency ?? ''}) - {formatAmount(transaction?.transaction?.receiving_amount, LANGUAGE)} ({transaction?.transaction?.receiving_currency ?? ''})</td>
                                                </tr>
                                                <tr>
                                                    <td>Exchange rate:</td>
                                                    <td>{transaction?.transaction?.exchange_rate}</td>
                                                </tr>
                                                <tr>
                                                    <td>Sending Fees:</td>
                                                    <td>{formatAmount(transaction?.transaction?.sending_fees, LANGUAGE)} ({transaction?.transaction?.sending_currency})</td>
                                                </tr>
                                                <tr>
                                                    <td>Tax:</td>
                                                    <td>{formatAmount(transaction?.transaction?.sending_tax, LANGUAGE)} ({transaction?.transaction?.sending_currency})</td>
                                                </tr>
                                                <tr>
                                                    <td>Total Amount:</td>
                                                    <td>{formatAmount(transaction?.transaction?.total_amount, LANGUAGE)} ({transaction?.transaction?.sending_currency})</td>
                                                </tr>
                                                <tr>
                                                    <td>Status:</td>
                                                    <td>{transaction?.transaction?.status}</td>
                                                </tr>
                                                <tr>
                                                    <td>Purpose of transaction:</td>
                                                    <td>{transaction?.transaction?.reason}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {isCancelable(transaction.transaction?.status) && (
                                            <div className="row">
                                                <div style={{ width: '40%' }}>
                                                    <p className='fw-bold'>{t('ACTIONS')}</p>
                                                </div>
                                                <div style={{ width: '60%' }}>
                                                    {transaction.transaction?.cancellation_requested === 1 ? (
                                                        <span className='badge bg-danger text-white p-2'>{t('CANCELLATION_REQUESTED')}</span>
                                                    ) : (
                                                        <button
                                                        className='btn btn-danger btn-sm'
                                                        type='button'
                                                        onClick={() => handleCancelClick(transaction)}
                                                        >
                                                        {t('CANCEL')}
                                                        </button>
                                                    )}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CustomModal
                showModal={showModal}
                title={t('CANCELLATION_REQUEST')}
                onClose={toggleModal}
                onSubmit={() => formikRef.current.submitForm()}
                submitButtonText={t('SUBMIT')}
                t={t}
                modalChildrenBody={
                    loading ? (
                    <div className="text-center">
                        <CustomLoading />
                    </div>
                    ) : (
                    <RequestCancelOrRequestCancelDetails
                        t={t}
                        selectedTransaction={transaction?.transaction}
                        cancelOrRequestCancel={cancelOrRequestCancel}
                        updateTransactionStatus={updateTransactionStatus}
                        toggleModal={toggleModal}
                        formikRef={formikRef}
                    />
                    )
                }
            />
        </div>
    )
}

export default SendMoneyDetails;