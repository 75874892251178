import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from 'react-redux';
import { useAuthUser } from 'react-auth-kit';
import LocalSendFees from './localSendingUtils/LocalSendFees';
import { setCurrentStep, setPaymentMethod, setReceiverDetails, setReceivingAmount, setSenderDetails, setSendingAmount, setTransactionDetails, setTransactionFees } from '../../slices/local-sending-slice';
import { useNavigate } from 'react-router-dom';
import { showToast } from '../../slices/toast-slice';
import { hideLoading, showLoading } from '../../slices/loading-slice';

export default function LocalQuotation({LANGUAGE}) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const authUser = useAuthUser();
    const navigate = useNavigate();

    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const sendingAmt = useSelector(state => state.localSending.sendingAmount);
    const receivingAmt = useSelector(state => state.localSending.receivingAmount);
    const selectedPaymentMethod = useSelector(state => state.localSending.paymentMethod);
    const [sending_limit, setSendingLimit ] = useState({
        "min_val": 0,
        "max_val": 0
    });

    const [ showLimits, setShowLimits ] = useState(false);
    const [sendingAllowed, setSendingAllowed] = useState(false);

    const feesObj = useSelector(state => state.localSending.transactionFees);
    const paymentMethods = [
        {
            abb: "CP",
            abb_name: "Cash Pickup"
        },
        // {
        //     abb: "BD",
        //     abb_name: "Bank Deposit"
        // },
        // {
        //     abb: "MW",
        //     abb_name: "Mobile Wallet"
        // }
    ];

    const currentStep = useSelector(state => state.localSending.currStep);

    useEffect(() => {
        // check if the authenticated user has a valid passcode
        const signature = globalAuthUser?.signature;

        if (globalAuthUser?.location?.agent_country?.enable_passcode === '1') {
            if(!signature) {
                // no signature is set for the user, prompt the user for a new signature
                dispatch(showToast(['danger', t("PASSCODE_ERROR"), t('PASSCODE_NOT_FOUND')]));
                navigate('/create-passcode');
                return;
            }

            if(!signature?.isActive || signature?.isActive == 2) {
                // signature code not activated
                dispatch(showToast(['danger', t("PASSCODE_ERROR"), t("PASSCODE_INACTIVE")]));
                navigate('/activate-passcode');
                return;
            }
        }

        // set the sending allowed
        setSendingAllowed(true);
    }, [])

    const onSendAmountChange = (e) => {
        // get the sending amount entered
        const input_sending_amt = e.target.value;
        const countryDetails = globalAuthUser.location.agent_country;

        // updating the sending amount state
        dispatch(setSendingAmount(input_sending_amt));

        if(input_sending_amt === 0 || input_sending_amt === "") {
            return;
        }

        // call the fees estimate variable
        let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/localSend/getFees`;
        axios({
            method: "post",
            url: url,
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `${authUser().tokenType} ${authUser().token}`,
            },
            data: {
                "sending_amount": input_sending_amt,
                "sending_country_code": countryDetails.isoname,
                "sending_currency": countryDetails.currency,
                "receiving_country_code": countryDetails.isoname,
                "receiving_currency": countryDetails.currency,
                "receiving_option_code": selectedPaymentMethod[0].abb
            }
        }).then((res) => {
            const responseData = res.data;
            if(responseData.status){
                // update the receiving amount
                dispatch(setReceivingAmount(responseData.data.receive_amount));
                dispatch(setTransactionFees(responseData.data));
                setShowLimits(false);
                return;
            }

            // extract the minimum and maximum amount
            resetQuotations();
            let limits_data = responseData.data;
            if(limits_data){
                var limits = {
                    min_val: limits_data?.minimum_amount,
                    max_val: limits_data?.maximum_amount
                }
                setSendingLimit(limits);
                setShowLimits(true);
                return;
            }
            
            // show the error toast
            dispatch(showToast(["warning", "Error", responseData.message]));
        }).catch((err) => {
            console.log(err);
            dispatch(showToast(["danger", t("SERVER_ERROR"), t("ERROR_HAS_OCCURED")]));
        });
    }

    // handle the next function
    const handleNext  = async () => {
        // make sure sending and receiving amounts are set
        if(!sendingAmt || !receivingAmt) {
            // show the error message
            dispatch(showToast(['danger', 'Error', t("PLEASE_ADD_LOCAL_SENDING_AMOUNT")]));
            return;
        }

        // console.log("globalAuthUser", globalAuthUser);

        // console.log("feesObj", feesObj);

        // return;

        let isBalanceOk = false
        let balanceResp = await checkAgentBalance()
        console.log('in 1')
        if(globalAuthUser.location.max_credit_limit != null) {
            console.log('in 1')
            if(balanceResp != null && (Number(balanceResp.balance) + Number(globalAuthUser.location.max_credit_limit) >= Number(feesObj.total_amount)) ) {
                isBalanceOk = true
                console.log('in 2')
            }
        } else if(globalAuthUser.location.bnb_owned != 1) {
            console.log('in 3')
            if(balanceResp != null && (Number(balanceResp.balance)  >= Number(feesObj.total_amount)) ) {
                isBalanceOk = true
            }
        }

        if(isBalanceOk == false) {
            dispatch(
                showToast(["danger", "ERROR", t("INSUFFICIENT_BALANCE")])
            )
            return;
        }

        // update the current state value
        dispatch(setCurrentStep(currentStep + 1))
    }

    async function checkAgentBalance() {
        dispatch(showLoading());
        try {
            const res = await axios({
                method: "get",
                url: process.env.REACT_APP_BNB_BASE_URL + "/api/portal/locationBalance",
                withCredentials: false,
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    'Authorization': authUser().tokenType + ' ' + authUser().token
                }
            })
            console.log(res.data.data)
            dispatch(hideLoading());
            // return res;
            if (res.data.status == 1) {
               return res.data.data
            } else return null
        }
        catch (error) {
            console.log(error)
            dispatch(hideLoading());
            return null
        }
    }

    const handleClose = () => {
        // reset the sender and receiver details
        dispatch(setSenderDetails({}));
        dispatch(setReceiverDetails({}));
        dispatch(setTransactionFees({}));
        dispatch(setReceivingAmount(0));
        dispatch(setSendingAmount(0));
        dispatch(setTransactionDetails({}));

        navigate('/home');
    }

    const resetQuotations = () => {
        dispatch(setReceivingAmount(0));
        dispatch(setTransactionFees({}));
    }

    const handlePaymentMethodChange = (e) => {
        let tmpReceivingOpt = paymentMethods.filter(val => val.abb === e.target.value);
        tmpReceivingOpt.length ? dispatch(setPaymentMethod(tmpReceivingOpt[0])) : dispatch(showToast("error", "Error", "Invalid payment method"));
    }

    return (
        <>
            {/** the sending amount */}
            <div className="row" style={{ padding: "10px" }}>
                <div className="col-12 col-lg-2"></div>
                <div className='col-12 col-lg-8'>
                    <label htmlFor='local_send_amt'> { t("HOW_MUCH_WOULD_YOU_LIKE_TO_SEND") } <span style={{ color: "red" }}>*</span></label>
                    <div className="border border-2 rounded-top px-2 py-1">
                        <label htmlFor="">{`${globalAuthUser.location.agent_country.name} (${globalAuthUser.location.agent_country.currency})`}</label>
                    </div>
                    <input type="text" className='form-control border-2 rounded-0 rounded-bottom' id="local_send_amt" value={sendingAmt} 
                        onChange={onSendAmountChange} 
                    />
                    {
                        showLimits && 
                        <small>
                            <span style={{ color: "red" }}>Sending amount range: { sending_limit.min_val } - { sending_limit.max_val }</span>
                        </small>
                    }
                </div>
            </div>

            {/** the sending amount */}
            <div className="row mb-3 border-bottom" style={{ padding: "10px" }}>
                <div className="col-12 col-lg-2"></div>
                <div className='col-12 col-lg-8'>
                    <label htmlFor='local_receiving_amt'> { t("YOU_RECIPIENT_WILL_RECEIVE") } <span style={{ color: "red" }}>*</span></label>
                    <div className="border border-2 rounded-top px-2 py-1">
                        <label htmlFor="">{`${globalAuthUser.location.agent_country.name} (${globalAuthUser.location.agent_country.currency})`}</label>
                    </div>
                    <input type="text" className='form-control border-2 rounded-0 rounded-bottom' id="local_receiving_amt" value={receivingAmt} readOnly/>
                </div>
            </div>


            {/** payment method selector */}
            <div className="row mb-3 border-bottom mb-2" style={{ padding: "10px" }}>
                <div className="col-12 col-lg-2"></div>
                <div className='col-12 col-lg-8'>
                    <label htmlFor='local_payment_method'> { t("HOW_WILL_YOUR_RECIPIENT_BE_PAID") } <span style={{ color: "red" }}>*</span></label>
                    <select value={selectedPaymentMethod[0].abb} name="local_payment_method" id="local_payment_method" className="form-control rounded-0 rounded-bottom" 
                    readOnly>
                        {
                            paymentMethods.map((tmpMethod, i) => (
                                <option key={tmpMethod.abb} value={tmpMethod.abb}>{tmpMethod.abb_name}</option>
                            ))
                        }
                    </select>
                </div>
            </div>

            {/** the summary component */}
            <LocalSendFees {...feesObj} LANGUAGE={LANGUAGE}/>

            {/** control buttons */}
            <div className="row mb-2">
                <div className="col-12 col-lg-2"></div>
                <div className="col-12 col-lg-8 d-flex justify-content-between">
                    <button type='button' className="btn btn-sm btn-dark" onClick={handleClose}>
                        { t("CLOSE") }
                    </button>
                    {
                        (sendingAmt != 0 && receivingAmt != 0) &&
                        <button type='button' className="btn btn-sm btn-primary" onClick={handleNext}>
                            { t("NEXT") }
                        </button>
                    }
                    
                </div>
            </div>
        </>
    )
}
