import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from "react-router-dom";
import { showToast } from '../../slices/toast-slice';
import { hideLoading, showLoading } from '../../slices/loading-slice';
import { formatAmount } from '../../utils/helper';

export default function MerchantPaymentShow() {
    const { paymentRef } = useParams();
    const { t } = useTranslation();
    const authUser = useAuthUser();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const LANGUAGE = useSelector((state) => state.auth.LANGUAGE);

    const [paymentDetails, setPaymentDetails] = useState({})
    const [paymentStatus, setPaymentStatus] = useState({})

    useEffect(() => {
        dispatch(showLoading());

        const headers = {
            Accept: "application/json",
            "Content-Type": "application/json",
            "Authorization": authUser().tokenType + ' ' + authUser().token
        };

        let url = `${process.env.REACT_APP_BNB_BASE_URL}/api/portal/merchant/report`;
        const payload = {
            reference_no: paymentRef
        }
        axios.post(url, payload, { headers }).then(res => {
            dispatch(hideLoading())
            var response = res.data.data;
            var paymentData = response?.payments?.data;
            var paymentStats = res?.data?.extraParams?.statuses
            setPaymentDetails(paymentData[0]);
            setPaymentStatus(paymentStats);
        }).catch(err => {
            console.log(err);
            dispatch(showToast(['danger', 'error', t('SOMETHING_WENT_WRONG')]));
            dispatch(hideLoading());
            navigate('/merchantPaymentReport')
        });

    }, []);

    
  return (
        <div style={{ padding: "20px" }}>
            <div className="card">
                <div className="card-header d-flex justify-content-between">
                    { t("PAYMENT_DETAILS") + ": " + paymentDetails?.reference_no }

                    <button type="button" className="btn btn-sm btn-primary" onClick={() => navigate('/merchantPaymentReport')}>
                        { t('BACK') }
                    </button>
                </div>

                <div className="card-body">
                    {/* main row */}
                    <div className="row mx-3">
                        
                        {/* container column */}
                        <div className="col-12 col-lg-6 p-2">

                            {/* payer details */}
                            <div className="mb-3">
                                <h5 className='mb-2'>{t('CUSTOMER_DETAILS')}</h5>
                                
                                <div className="mb-2 d-flex justify-content-between">
                                    <span>{t('FIRSTNAME')}:</span>
                                    <span>{paymentDetails?.payer_firstName}</span>
                                </div>

                                <div className="mb-2 d-flex justify-content-between">
                                    <span>{t('LASTNAME')}:</span>
                                    <span>{paymentDetails?.payer_LastName}</span>
                                </div>

                                <div className="mb-2 d-flex justify-content-between">
                                    <span>{t('PHONE_NO')}:</span>
                                    <span>{paymentDetails?.account_id}</span>
                                </div>

                                <div className="mb-2 d-flex justify-content-between">
                                    <span>{t('COUNTRY')}:</span>
                                    <span>{paymentDetails?.country_code}</span>
                                </div>

                                <div className="mb-2 d-flex justify-content-between">
                                    <span>{t('ACCOUNT_NUMBER')}:</span>
                                    <span>{paymentDetails?.payer_account_id}</span>
                                </div>

                                <div className="mb-2 d-flex justify-content-between">
                                    <span>{t('RCV_MERCHANT_CODE')}:</span>
                                    <span>{paymentDetails?.rcv_merchant_code}</span>
                                </div>

                            </div>

                            {/* merchant details */}
                            <div className="mb-3">
                                <h5 className='mb-2'>{t('TRANSACTION_DETAILS')}</h5>

                                <div className="mb-2 d-flex justify-content-between">
                                    <span>{t('AMOUNT')}:</span>
                                    <span>
                                        { paymentDetails?.amount ? formatAmount(paymentDetails?.amount, LANGUAGE) : "0.00"}
                                        {` (${paymentDetails.currency ? paymentDetails.currency : ''})`}
                                    </span>
                                </div>

                                <div className="mb-2 d-flex justify-content-between">
                                    <span>{t('transactionStatus')}:</span>
                                    <span>
                                        {paymentStatus[paymentDetails?.txn_status]}
                                    </span>
                                </div>

                                <div className="mb-2 d-flex justify-content-between">
                                    <span>{t('TYPE')}:</span>
                                    <span>{paymentDetails?.category}</span>
                                </div>

                                <div className="mb-2 d-flex justify-content-between">
                                    <span>{t('PAID_AT')}:</span>
                                    <span>{paymentDetails?.created_at}</span>
                                </div>
                            </div>
                            
                        </div>
                        {/* end container column */}

                    </div>
                    {/* end main row */}
                </div>
            </div>
        </div>
  )
}
