import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideLoading, showLoading } from "../../slices/loading-slice";
import axios from "axios";
import { useAuthUser } from "react-auth-kit";
import { t } from "i18next";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatAmount, formatInDateTime } from "../../utils/helper";
import CustomDuration from "../CustomDuration";
import CustomPaginateOption from "../CustomPaginateOption";

const EcobankXpress = () => {
    const dispatch = useDispatch();
    const authUser = useAuthUser();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const globalAuthUser = useSelector((state) => state.auth.globalAuthUser);
    const LANGUAGE = useSelector((state) => state.auth.LANGUAGE);
    let cmpt = 0;
    const [useDetails, setUserDetails] = useState(null);
    const [transactions, setTransactions] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [fromPage, setFromPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [fromElmt, setFromElmt] = useState(0);
    const [toElmt, setToElmt] = useState(0);
    const [recordPerPage, setRecordPerPage] = useState(10);
    const npage = Math.ceil(total/recordPerPage);
    const [duration, setDuration] = useState("1");
    const [statuses, setStatuses] = useState([]);
    
    const [account, setAccount] = useState({
        duration: 1,
        status: '',
        from_date: '',
        to_date: '',
        agent_country_id: globalAuthUser?.location?.agent_country?.id,
        agent_location_id: globalAuthUser?.location?.id,
        per_page:10
    });
    const numbers = [];
    for (let i = 1; i <= npage; i++) {
        numbers.push(i);
    }

    
    //Cummon function to fetch data
    function fetchData(url,currentPage, payload) {
        const headers = {
            'Authorization': authUser().tokenType + ' ' + authUser().token
        };
        const data = payload;
        dispatch(showLoading());
        axios.post(url, data, { headers })
        .then(response => {
            dispatch(hideLoading());
            setStatuses(response.data.data.statuses)
            setFromPage(response.data.data.xpress_cashin_transactions.from);
            setTransactions(response.data.data.xpress_cashin_transactions.data);
            setTotal(response.data.data.xpress_cashin_transactions.total);
            setFromElmt(response.data.data.xpress_cashin_transactions.from);
            setToElmt(response.data.data.xpress_cashin_transactions.to);
            setRecordPerPage(response.data.data.xpress_cashin_transactions.per_page);
            setCurrentPage(currentPage);
        })
        .catch(error => {
            console.error(error);
        });
    }

    useEffect(() => {
        axios({method: 'get', url: process.env.REACT_APP_BNB_BASE_URL + '/api/portal/userProfileWithData', 
              withCredentials: false,
              headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                'Authorization': authUser().tokenType + ' ' + authUser().token
              }
            }).then(res => {
              console.log(res);
              setUserDetails(res.data.data)
            })
    }, []);

    useEffect(() => {
        account.duration = duration
        var payload = account
        fetchData(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getXpressCashins',
        currentPage,
        payload);
    }, []);

    const currentAccounts = transactions;
    var listAccounts = "";
    // console.log((statuses));
    listAccounts = currentAccounts.map((item, index)=> {
            const {id,reference_no,beneficiary_account,amount,currency,sender_phone_number,sender_name,status,description,created_at,
            } = item;
        return (
            <tr key={id} >
                <td>{fromPage+index}</td>
                <td>
                    <button type="button" className="btn btn-link" onClick={() => {
                            navigate('/xpress-cashin-details/' + id)
                        }}>
                        {reference_no}
                    </button> 
                </td>
                <td>{beneficiary_account}</td>
                <td>{formatAmount(amount, LANGUAGE)} {currency}</td>
                <td>{sender_name}</td>
                <td>{sender_phone_number}</td>
                <td>{status != "" && status!= null ? (status === "new" ? "In Progress" : statuses[status]) : status}</td>
                <td>{formatInDateTime(created_at)}</td>
                <td>{description}</td>
            </tr>
        )
    });
    //This function is used to change the page on pagination
    function changeCPage(page) {
        dispatch(showLoading());
        account.duration = duration
        var payload = account
        fetchData(`${process.env.REACT_APP_BNB_BASE_URL}/api/portal/getXpressCashins?page=${page}`,
        page,
        payload)
    }

    const handleInput = (e) => {
        e.persist();
        setAccount((prevAccount) => {
          const updatedAccount = { ...prevAccount, [e.target.name]: e.target.value };
          if (e.target.name === "per_page") {
            account.per_page = e.target.value;
            searchByParam(e);
          }
          if (e.target.name === 'from_date') {
            const from_date = e.target.value;
            updatedAccount.from_date = from_date;
            
            const fromDateObject = new Date(from_date);
            const toDateObject = new Date(
              fromDateObject.getFullYear(),
              fromDateObject.getMonth() + 1,
              fromDateObject.getDate()
            );
            const to_date = toDateObject.toISOString().split('T')[0];
            updatedAccount.to_date = to_date;
          }
          return updatedAccount;
        });
    };
      

    function handleDurationChange(event) {
        setDuration(event.target.value);
    }
    function handleDateInvalid(event) {
        event.target.setCustomValidity("Please select a date.");
    }

    //This is the function to reset all the param
    function resetSearch(event) {
        var myForm = document.getElementById('my-form');
        myForm.reset();
        setDuration(1);
        account.reference_no = "";
        dispatch(showLoading());
        event.preventDefault();
        var payload = account
        
        fetchData(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getXpressCashins',
        currentPage,
        payload);
    }


    //This function is used to search by param like duration 
    function searchByParam(event) {
        dispatch(showLoading());
        event.preventDefault();
        account.duration = duration
        var payload = account
        if(duration === "6"){
            const from_date_error = document.getElementById('from_date_error');
            const to_date_error = document.getElementById('to_date_error');
            if (account.from_date === "") {
                from_date_error.textContent = 'Please enter a valid date';
                dispatch(hideLoading());
                return ;
            } else {
                from_date_error.textContent = '';
            }
            if (account.to_date === "") {
                to_date_error.textContent = 'Please enter a valid date';
                dispatch(hideLoading());
                return ;
            } else {
                to_date_error.textContent = '';
            }
        }
        
        fetchData(process.env.REACT_APP_BNB_BASE_URL + '/api/portal/getXpressCashins',
        currentPage,
        payload);
    }
    
    return (
        <React.Fragment>
            <div style={{ padding: "20px" }}>
                <div className="card">
                    <div className="card-header row">
                        <h5 className="col-sm-4"> </h5>
                        <h4 className="col-sm-4">
                            <p> <span style={{ fontSize: '23px', marginRight: '10px'}}>Balance: </span> <span style={{ fontSize: '23px', fontWeight: 'bold'}}>
                                { formatAmount(useDetails?.location?.balance, LANGUAGE) + ' ' + useDetails?.location?.currency }
                                </span> 
                            </p>
                        </h4>
                        <span className="col-sm-4 pull-right" >
                            <div className="card-toolbar float-end mb-2 mt-2">
                                <div className="pull-right">
                                    <button type='button' className='btn btn-primary' onClick={() => navigate('/xpress-cashin-create')}>
                                        {t('NEW_XPRESS')}
                                    </button>
                                </div>                 
                            </div>
                        </span>
                    </div>
                    
                    <div className="card-body">
                        <form id="my-form">
                            <div className="form-group row justify-content-md-center">
                                <div className="col-md-3">
                                    <label className=""  htmlFor="status">{t('STATUS')}</label>
                                    <select className="form-select" onChange={handleInput} name="status" id="status" aria-label="Default select example">
                                        <option value={""}>{t('SELECT_A_STATUS')}</option>
                                        {
                                            Object.keys(statuses).map((key, index) => (
                                                <option key={index} value={key}>
                                                {statuses[key]}
                                                </option>
                                            ))
                                        }
                                    </select>
                                </div>
                                
                                <CustomDuration 
                                    duration={duration} 
                                    handleDurationChange={handleDurationChange} 
                                    handleDateInvalid={handleDateInvalid}
                                    handleInput={handleInput}
                                    from_date={account.from_date}
                                    to_date={account.to_date}
                                />
                            </div>
                            
                            <div className="form-group row justify-content-md-center mt-3">
                                <button type="submit" onClick={searchByParam} className="col-sm-2 btn btn-primary" id="apply-filter-paid" data-count="0">{t('SEARCH')}</button>
                                &nbsp;
                                <button type="reset" onClick={resetSearch} className="col-sm-2 btn btn-secondary" id="apply-filter-clear-paid">{t('CLEAR_SEARCH')}</button>
                            </div>
                            <div className="per_page m-2">
                               <CustomPaginateOption 
                                    per_page={account.per_page}
                                    handleInput={handleInput}
                                    total={total}
                                /> 
                            </div>
                        </form>
                        <table id="datatable" className="table table-bordered grid-table responsive nowrap w-100">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>{t('REFERENCE')}</th>
                                    <th>{t('BENEFICIARY_ACCOUNT')}</th>
                                    <th>{t('AMOUNT')}</th>
                                    <th>{t('SENDER_NAME')}</th>
                                    <th>{t('SENDER_PHONE_NUMBER')}</th>
                                    <th>{t('STATUS')}</th>
                                    <th>{t('DATE')}</th>
                                    <th>{t('DESCRIPTION')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {listAccounts}
                            </tbody>
                            
                        </table>
                    </div>
                    <div className="card-footer">
                        
                        {
                            numbers.length > 1 ? (
                                <>
                                    <div className="pagination" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <div className="left-info">
                                            <span>
                                            {t('SHOWING')} {fromElmt} {t('TO')} {toElmt} {t('OF')} {total} {t('ENTRIES')}
                                            </span>
                                        </div>
                                        <div className="float-end">
                                            <nav aria-label="Page navigation example">
                                                <ul className="pagination" style={{ display: 'flex', listStyle: 'none', margin: 0, padding: 0 }}>
                                                    <li className="page-item">
                                                        <button type="button" onClick={() => changeCPage(currentPage-1)} className={`page-link ${currentPage === 1 ? 'disabled' : ''}`} aria-label="Previous">
                                                            <span aria-hidden="true">&laquo;</span>
                                                        </button>
                                                    </li>
                                                    
                                                    {numbers.map((page, index) => (
                                                    <li className={`page-item ${currentPage === page ? 'active' : ''}`} key={index}>
                                                        {
                                                            (
                                                                page === 1 || 
                                                                page === numbers.length || 
                                                                currentPage === page || 
                                                                currentPage+1 === page || 
                                                                currentPage+2 === page ||
                                                                currentPage-1 === page || 
                                                                currentPage-2 === page )  ? 
                                                                (<button type='button' onClick={() => changeCPage(page)} className="page-link">
                                                                    {
                                                                        page
                                                                    }
                                                                </button>) 
                                                            : ((cmpt === 1 && (page === currentPage+3)) ? (
                                                                <button className="page-link">
                                                                  {(() => {
                                                                    cmpt++;
                                                                    return '...';
                                                                  })()}
                                                                </button>
                                                              ) : ((cmpt === 0) ? (
                                                                <button className="page-link">
                                                                  {(() => {
                                                                    cmpt++;
                                                                    return '...';
                                                                  })()}
                                                                </button>
                                                              ) : null))
                                                        }
                                                    </li>
                                                    ))}
                                                    <li className="page-item">
                                                        <button type="button" onClick={() => changeCPage(currentPage+1)} className={`page-link ${currentPage === numbers.length ? 'disabled' : ''}`} aria-label="Previous">
                                                            <span aria-hidden="true">&raquo;</span>
                                                        </button>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>

                                </>
                            ) : (
                                <></>
                            )
                        }
                    </div>
                </div> 
            </div>
        </React.Fragment>
    );
}

export default EcobankXpress;